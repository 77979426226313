/*============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/
@import "sass-lib";

@media all and (min-width: 1023px) {
    header{
        .main_menu{
            nav{display: block !important; opacity: 1 !important; }
        }
    }
}
@media all and (max-width: 1023px) {

.wrap_body {
    &.fixed {overflow: hidden !important; position: fixed; left: 0; right: 0; top: 0; bottom: 0; }
}
.container {width: 690px;; margin: 0 auto;}
.wrapper {width: 690px;}
h1 {font-size: 30px;}
h5 {font-size: 20px;}
p {font-size: 16px; line-height: 24px; margin-bottom: 20px;}
.btn {font-size: 16px;}

/* header
----------------------------------------------------------------------------------------------*/
header {
    padding-top: 15px; height: 80px;
    .logo {width: 115px;}
    .main_menu {
        nav {
            display: none; position: fixed; left: 0; right: 0; top: 0; bottom: 0; background: rgba(0,0,0,0.9);
            z-index: 98; min-height: 480px; overflow-x: auto;
            > ul {
                height: 450px; position: absolute; bottom: 0; right: 0; top: 0; left: 0; margin: auto;
                li {
                    float: none; width: auto; display: block; text-align: center;
                    > a {
                        padding: 30px 0; color: #fff; font-weight: 800; font-size: 30px;
                        &:before {display: none;}
                    }
                    &.prod {
                        &:hover {
                            >a {
                                &:after {
                                    display: none;
                                }
                            }
                            .sub_prod {
                                display: none !important;
                            }
                        }
                    }
                }
            }
        }
        .cart {
            /*> a {
                width: 40px; height: 40px;
                span {
                    width: 26px; height: 26px;
                    &.tot_cart {width: 24px; height: 24px; line-height: 24px;}
                }
            }*/
            .drop_cart {
                right: 45px;
                .box_cart {
                    width: 320px;
                    .list_cart {
                        .cart_item {
                            .ket {width: 220px;}
                        }
                    }
                }
            }
        }
        .burger_mnu, .close_mnu {
            float: left; width: 40px; position: relative; height: 29px; margin: 10px 0 0 30px; z-index: 99; display: block; cursor: pointer;
            span {
                display: block; position: absolute; width: 40px; top: 0; bottom: auto; height: 4px;
                background: $c_red; right: 0; font-size: 0; @include borderRadius(2px);
                &:nth-child(2) {top: 0; bottom: 0; margin: auto; width: 30px;}
                &:nth-child(3) {top: auto; bottom: 0; width: 40px;}
            }
        }
        .burger_mnu {display: block !important;}
        .close_mnu {
            float: right; position: relative; top: 14px; right: 38px; z-index: 99; display: none;
            span {
                &:nth-child(2) {opacity: 0;}
                &:nth-child(1) {
                    top: 13px; bottom: auto; @include transform(rotate(-45deg));
                }
                &:nth-child(3) {
                    top: 13px; bottom: auto; @include transform(rotate(45deg));
                }
            }
        }
    }
}
.mobile_prod {
    display: none;
    .sub_prod {
        display: block;top: 0; position: fixed; left: 0;  right: 0; background: #fff; width: auto; border-top: 3px solid #000;
        .wrapper {width: 690px;}
        .close_tab {display: block;}
        .hov_product {
            @include boxShadow(none); border-top: none;-webkit-tap-highlight-color: rgba(0,0,0,0);@include appearance(none);
            height: 100%;
            &:focus {background: none;}        
            *{
                -webkit-tap-highlight-color: rgba(0,0,0,0);
            }
        }
        .content_tab {
            background: #fff;
            .tab_list {
                width: 600px;
                h6 {margin-bottom: 30px;}
                .tabBrand {
                    .slider01,.slider02 {
                        .brand {
                            .listBrand {height: 346px;}
                        }
                    }
                }
            }
        }
    }
    .sub_bike {
        padding: 20px 0 20px 0;
        .bikes_sub {
            .nme_bike {width: 150px;}
            .listBike {
                width: 525px; height: 298px;
                .slide_bike {width: 520px;}
                .bike {
                    background: #fff; height: 290px;
                    ul {
                        li {
                            width: 170px;
                            &:nth-child(7),
                            &:nth-child(8) {display: none;}
                        }
                    }
                }
                .slick-arrow {
                    top: auto; bottom: -40px; @include transform(rotate(-90deg));
                    &.slick-next {left: auto; right: 20px;}
                    &.slick-prev {left: 0; right: auto;}
                }
            }
        }
    }
}

/* middle homepage
----------------------------------------------------------------------------------------------*/
.homepage {
    .homeSlide {
        .caption {
            position: absolute; top: auto; bottom: 25%;
            .container {width: 400px;}
            h1 {
                font-size: 45px;
                &.second {padding-left: 0;}
            }
        }
        .images {
            img {max-width: 1600px;}
        }
        /*.slick-dots {
            right: 40px; top: 30%;
            li {
                button {
                    line-height: 42px; height: 42px; font-size: 13px;
                    &:before {
                        font-size: 13px;
                    }
                    &:after {
                        font-size: 14px;
                    }
                }
                &.slick-active,
                &:hover {
                    button {
                        font-size: 30px;
                        &:before {
                            font-size: 30px;
                        }
                    }
                }
            }
        }*/
    }
}

.pg_title {font-size: 30px;}
.text {
    p {font-size: 16px; line-height: 26px; margin-bottom: 20px;}
}
.sec_about {
    padding-bottom: 60px;
    .img {float: none; max-width: 500px; margin: 0 auto; display: block; width: auto;}
    .text {
        &.about {
            float: none; width: auto; padding-top: 0;
        }
    }
}
.sec_milestones {
    padding-bottom: 100px;
    .nav-direction {
        top: 420px;
        a {
            top: 0px; z-index: 5; bottom: auto;
            &.prev {left: 15px;}
            &.next {right: 15px;}
        }
    }
    .miles_content {
        padding: 0;
        .slide_miles {
            .miles {
                .images {
                    width: auto; float: none; height: 400px; overflow: hidden; position: relative;
                    img {display: block; position: relative; margin: -20% auto;  width: 100%;}
                    p.ket_year {
                        position: absolute; bottom: 0; z-index: 5; background: #fff; left: 0; right: 0;
                        margin-top: 0;padding-top: 30px; text-align: center; font-size: 30px;
                        span {width: 10px;  display: block; margin: 0 auto; float: none; height: 10px; background: no-repeat; margin-bottom: 10px;}
                    }
                }
                .dec_miles {
                    width: auto; float: none; margin-top: 75px;
                }
            }
        }
    }
}
.sec_meetOur {
    padding-bottom: 80px;
    .box_img {
        width: auto; float: none;
        .slider {
            padding-top: 100px;
            .txt_meet {
                height: 360px; border: 2px solid #ccc;
                padding: 0 40px;
                h4 {
                    font-size: 22px; padding-left: 55px; background-size: 38px;
                    span {font-size: 16px;}
                }
                .name {width: 335px;}
            }
        }  
        .slick-arrow {
            right: 45px; bottom: -65px; z-index: 5;
            &.slick-prev {}
            &.slick-next { right: 0; }
        }
    }
    .img {width: 280px;}
    .right_meet {
        padding-top: 100px; width: auto; float: none; text-align: left;
        .pg_title.rh {
            padding-left: 30px; padding-right: 0;
            &:after {
                right: auto; left: 10px;
            }
        }
        p {padding-left: 10px;}
    }
}
.sec_testimoni {
    .pg_title {margin-bottom: 50px;}
    .block_testi {
        .Imgtestimoni {
            .slick-arrow {
                &.slick-next {left: -81%;}
            }
        }
        .textTestimoni {
            .slide {
                .txt_testimoni {
                    .round_img {
                        .fto {width: 70px; height: 70px;}
                    }
                }
            }
        }
    }
}



.page {padding-bottom: 255px;}
.prod_pg, .news_pg, .poject_pg {
    .wrapper {width: 690px;}
}
/*.project.list {
    background-size: 150%; padding-top: 150px;
}*/
.breadcrumb {
    .pos {font-size: 18px;}
}
.top_fil {
    padding: 20px 25px;
    .sort {
        margin-right: 20px;
        label {font-size: 14px;}
        select {font-size: 14px;}
        &.dt {
            select  {width: 80px;}
        }
        &.med {
            select {width: 115px;}
        }
    }
    .left {float: none; display: block;}
    .right {
        float: none; display: block; margin-top: 10px; position: relative;
        form {
            position: relative; display: block;
            input[type="text"] {width:100%;}
            input[type="submit"] {position: absolute; right: 5px; top: 0; bottom: 0;margin: auto;}
        }
    }
    /*&.prod {
        .right {
            form {
                input[type="text"] {width: 160px;}
            }
        }
    }*/
}
.asside {
    width: 178px;
    .filter {
        h6 {padding: 20px 0 0;}
        padding: 0 15px 20px;
        #price_range {margin: 15px 0;}
        .box_price {
            .col {
                width: auto; margin-bottom: 15px;
                input {font-size: 12px; padding: 10px 8px;}
            }
        }
        .drop_filt {
            a {
                font-size: 13px;
                &:after {left: -16px;}
            }
        }
    }
}
.content_right {width: 505px;}
.col_list {
    .col {
        .img {
            .flag {
                width: 65px; height: 40px;
                span {font-size: 13px; left: 4px; bottom: 8px; width: 30px;}
            }
        }
        .ket_prod {
            padding: 0 15px 25px;
            span {font-size: 12px;}
        }
        .more_det {font-size: 15px;}
    }
    &.listProject {
        .col {
            padding: 20px 0;
            h6 {margin-top: 15px; font-size: 14px;}
            .img {
               width: 88%; margin: 0 auto;
            }
            
            &:nth-child(4n) {border-right: none;}
        }
    }
}
.load_more  {font-size: 16px; margin: 50px auto 0;}
/*.pagging {
    a {
        width: 26px; line-height: 26px; font-size: 16px;
        &:hover, &.active {background-size: 100%;}
    }
}
.qty_input {
    input {font-size: 20px;}
}*/
.related {
    .col_list {
        .col {
            width: 32.6%;
            &:nth-child(3) {margin-right: 0;}
            &:nth-child(4) {display: none;}
        }
    }
}

.detail_prod {
    padding-top: 40px;
    .img_big {
        padding-left: 30px; width: 38.68%;
        .list_img {width: 266px; height: 266px;}
        .thumb {
            a {width: 55px;}
        }
    }
    .dec_prod {
        width: 51.39%;
        h5 {font-size: 20px;}
        ul {
            width: 90%;
            li {font-size: 16px; margin-bottom: 15px; line-height: 20px;}
        }
        .pqt {
            margin-left: 0px;
            .ket{
                width: 48%;
                label {font-size: 16px;}
                &:nth-child(2) {
                    width: 48%; text-align: right;
                    .qty_input {display: inline-block;}
                }
                &:last-child {
                    margin-top: 15px; width: 100%; padding-top: 15px; border-top: 1px solid #ccc;
                }
            }
        }
        .btn {margin-left: 0;}
    }
}
.detail_listPro {
    .slideSmall {
        .thmb {margin: 0 12px;}
    }
}
/*.decription {
    p {font-size: 16px; line-height: 24px;}
}*/
.btm_cart {
    .right {
        h6 {
            font-size: 16px;
            span {font-size: 20px; margin-left: 20px;}
        }
    }
}
.cart_pg {
    padding: 30px 20px;
    .shopCart_list {
        .item {
            .blok {
                padding: 5px; width: auto !important;
                h5 {font-size: 14px; line-height: 30px;}
                label {font-size: 12px; margin-bottom: 10px;}
                &:nth-of-type(1) {width: 130px !important; padding: 5px 0;}
                &:nth-of-type(2) {
                    width: 140px !important; padding: 0 10px;
                    h6 {font-size: 14px; margin-bottom: 10px;}
                    h5 {line-height: 18px;}
                }
                &:nth-of-type(3), &:nth-of-type(5) {width: 150px !important; padding-left: 10px;}
                .qty_input {
                    width: 100px; margin-right: 10px;
                    input {width: 36px; font-size: 16px;}
                    button {font-size: 20px;}
                }
            }
            .rmove {width: 18px; height: 17px; background-size: 18px !important; right: 10px; top: 10px;}
        }
    }
    .ckout {
        .row {
            p {font-size: 16px; line-height: 24px;}
            label {font-size: 16px;}
            input[type="text"],input[type="email"],select,textarea {font-size: 16px;}
        }
        &.apply {
            .input_file {
                span {font-size: 16px;}
            }
        }
        /*input[type=submit].btn {margin-top: 15px;}*/
    }
}



.dot {font-size: 26px;}
.text_thanks {
    width: auto;
    h1 {font-size: 60px;}
    p {font-size: 18px; line-height: 26px; }
    &.karir {
        h1 {font-size: 50px; margin-bottom: 40px;}
        p {font-size: 20px;}
    }
}
/*.more_project {line-height: 24px; background-size: 24px; padding-left: 35px;}*/
.project {
    background: #fff url("../images/banner/bg_street.jpg") no-repeat top center;
}
.left_project {
    .project_content {
        .slide_project1  {
            .item {
                .images {
                    float: none; max-width: 500px; margin: 0 auto; width: auto;
                }
                .dec_project {
                    width: auto; float: none;
                    h1 {margin-bottom: 20px; line-height: 32px;}
                }
            }
        }
    }
    .thumb_project {
        float: none; width: 100%;
        .list_thumb {
            width: 525px; margin: 40px auto; padding-bottom: 20px;
            .slick-list {padding: 0 !important;}
            .thumb {
                height: 95px; margin: 20px 5px; background-size: 100% !important;
                &.slick-center {
                    @include transform(scale(1.4));
                }
            }
            .slick-arrow {
                @include transform(rotate(-90deg));
                &.slick-prev { top: 20%;}
                &.slick-next {top: 20%;}
            }
        }
    }
    .more_project {width: 185px;  display: block; margin: 0 auto; font-size: 16px;}
}
.slide_recomend {
    .slide {width: 545px;}
}
.SlideText {
    width: 545px; text-align: left;
    h4 {padding-right: 20%;}
}
.news_pg {
    .date {font-size: 16px; margin-bottom: 30px;}
    .rd_more {font-size: 16px;}
    .big_news {
        .hdline {
            .img {
                padding: 6% 0; height: 440px; overflow: hidden; width: 55.5%;
                img{max-width: 600px; margin-left: -25%;}
            }
            .dec_news {
                padding: 70px 30px; width: 44.5%;
                h1 {line-height: 40px; padding-right: 0;}
                p {height: 195px;  position: relative; overflow: hidden;}
            }
        }
    }
    .grid_news {
        width: 725px;
        .grid_item {
            width: 50%;
            .img {
                width: 325px;
                img {width: 100%;}
            }
            .dec {width: 325px;}
            &:nth-of-type(4) {margin-top: 0;}
            &:nth-of-type(even) {
                .img {bottom: 0; top: auto;}
                .dec {top: 0; bottom: auto;}
            }
        }
    }
}

.listCareer {
    .list {
        padding-bottom: 20px; margin-bottom: 30px;
        div {
            padding: 0 20px;
            h5 {font-size: 16px;}
            &.pos {padding-left: 25px;}
            &.submit {
                .btn {width: 165px; font-size: 16px;}
            }
            &.job {
                span {font-size: 14px;}
            }
            p {font-size: 14px; line-height: 20px;}
        } 
    }
}
.top_career {
    padding-bottom: 20px;
    h5 {font-size: 18px;}
    .loc {font-size: 16px;}
}
.contact_sec {
    h2 {font-size: 25px;}
    .list_location {
        .location {
            width: 48%; margin-right: 4%;
            h2 {font-size: 20px;}
            &:nth-child(3n) {margin-right: 4%;}
            &:nth-child(2n) {margin-right: 0;}
        }
    }
    .contact_form {
        .formContact {
            width: 41%;
            p {font-size: 16px; line-height: 24px;}
        }
    }
}

.scrolltoTop {bottom: 150px;}

/* footer
----------------------------------------------------------------------------------------------*/
footer {
    height: auto; padding-top: 0; overflow: hidden;
    .container {
         @include flex(column); display: flex;
    }
    .foot {
        margin: 12px 0; float: none; @include flex(column);
        &.left {
            display: block;float: none; @include order(2);
            ul {
                li {
                    margin: 0 3px;
                    a {width: 32px; height: 32px; background-size: 22px !important;}
                }
            }
        }
        &.center {
            position: relative; top: 0; @include order(1); padding: 0; margin: 20px 0 10px;
            ul {
                li {
                    margin-bottom: 0px;display: inline-block; margin: 0;
                    &:first-child {
                        text-align: right;
                        a {margin-right: 0;}
                    }
                    &:last-child {
                        a {margin-left: 16px; margin-right: 0;}
                    }
                }
                &:after {display: block; clear: both; content: '';}
            }
        }
        &.right {
            display: block; float: none; background: #ccc; margin: 0; padding: 10px 0; margin: 0 -200px;@include order(3);
            a {color: #000; border-right: 1px solid #000; font-size: 12px; line-height: 16px;}
        }
    }
}

/*.fancybox-slide .fancybox-image-wrap {left: 20px !important; right: 20px !important; width: auto !important; }*/



}